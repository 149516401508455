import React, { Component } from "react";
import { navigate } from "gatsby"

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
    };
  }

  componentDidMount() {
    console.log(window.location.href.split("?").slice(-1));
    if (window.location.href.split("?").length < 2) {
      navigate('/404');
      return;
    }

    const getMobileOS = () => {
      const ua = navigator.userAgent;
      console.log(ua);
      if (/android/i.test(ua)) {
        return "Android";
      } else if (/iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/i.test(ua)) {
        return "iOS";
      }else{
        return "Other";
      }
    };

    const os = getMobileOS();

    this.setState(
      {
        model: window.location.href.split("?").slice(-1),
      },
      () => {
        if (os === "Android") {
          window.location.replace(
            `intent://arvr.google.com/scene-viewer/1.0?file=https://virtual-showroom.forms-surfaces.net/AR/${
              this.state.model && this.state.model
            }.glb#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://virtual-showroom.forms-surfaces.net;end;`
          );
        } else if (os === "iOS") {
          window.location.replace(
            `https://virtual-showroom.forms-surfaces.net/AR/${
              this.state.model && this.state.model
            }.usdz`
          );
        }
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* {getMobileOS() === "Other" && ( */}
        <div className="wrapper">
          <div className="content m-4">
            <div className="row">
              <div className="col text-center">
                <a
                  id="apple-ar-link"
                  rel="ar"
                  href={`https://virtual-showroom.forms-surfaces.net/AR/${
                    this.state.model && this.state.model
                  }.usdz`}
                >
                  <i className="fab fa-apple"></i> Download .usdz
                </a>
              </div>
              <div className="col text-center">
                <a
                  href={`intent://arvr.google.com/scene-viewer/1.0?file=https://virtual-showroom.forms-surfaces.net/AR/${
                    this.state.model && this.state.model
                  }.glb#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://virtual-showroom.forms-surfaces.net;end;`}
                  download
                >
                  <i className="fab fa-android"></i> Download .glb
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </React.Fragment>
    );
  }
}

export default IndexPage;
